<script lang="ts">
import { defineComponent } from "vue";

import PrepareAssociate from "@/views/Certifications/assets/PrepareAssociate.vue";
import PrepareEntry from "@/views/Certifications/assets/PrepareEntry.vue";
import PrepareExpert from "@/views/Certifications/assets/PrepareExpert.vue";
import PrepareProfessional from "@/views/Certifications/assets/PrepareProfessional.vue";

export default defineComponent({
  emits: ["show-concentration"],
  components: {
    PrepareAssociate,
    PrepareEntry,
    PrepareExpert,
    PrepareProfessional,
  },
  props: {
    certification: {
      type: Object,
      required: true,
    },
    exCardCore: {
      type: Object,
      default: null,
    },
    exCardEntry: {
      type: Object,
      default: null,
    },
    exCardLab: {
      type: Object,
      default: null,
    },
    exCardConcentration: {
      type: Object,
      default: null,
    },
    communities: {
      type: Object,
      default: null,
    },
    recommendedPaths: {
      type: Array,
      default: () => [],
    },
    currentExam: {
      type: Object,
      default: null,
    },
    pathConc: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: Object,
      default: null,
    },
  },
});
</script>

<template>
  <prepare-associate
    data-cy="prepare-associate"
    v-if="certification.certification_level === 'Associate'"
    :exCardCore="exCardCore"
    :exCardEntry="exCardEntry"
    :communities="communities"
    :recommendedPaths="recommendedPaths"
    :status="status"
  />

  <prepare-entry
    data-cy="prepare-entry"
    v-else-if="certification.certification_level === 'Entry'"
    :exCardCore="exCardCore"
    :exCardEntry="exCardEntry"
    :communities="communities"
    :recommendedPaths="recommendedPaths"
    :status="status"
  />

  <prepare-expert
    data-cy="prepare-expert"
    v-else-if="certification.certification_level === 'Expert'"
    :exCardCore="exCardCore"
    :exCardEntry="exCardEntry"
    :exCardLab="exCardLab"
    :communities="communities"
    :recommendedPaths="recommendedPaths"
    :status="status"
  />

  <prepare-professional
    data-cy="prepare-professional"
    v-else-if="pathConc && certification.certification_level === 'Professional'"
    :certification="certification"
    :exCardCore="exCardCore"
    :exCardEntry="exCardEntry"
    :exCardConcentration="exCardConcentration"
    :communities="communities"
    :recommendedPaths="recommendedPaths"
    :current-exam="currentExam"
    :path-conc="pathConc"
    :status="status"
    @show-concentration="$emit('show-concentration', $event)"
  />
</template>
