import unauthenticatedHttp from "@/services/unauthenticatedHttp";

export async function fetchNewContent() {
  const { data } = await unauthenticatedHttp().get(
    `${window.env.UNAUTH_API}/contents?type=new`
  );

  return data;
}

export async function fetchFreeContent() {
  const { data } = await unauthenticatedHttp().get(
    `${window.env.UNAUTH_API}/contents?type=free`
  );

  return data;
}
