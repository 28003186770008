<script lang="ts">
import { defineComponent } from "vue";

import ScheduleEntryAssociate from "@/views/Certifications/assets/ScheduleEntryAssociate.vue";
import ScheduleExpert from "@/views/Certifications/assets/ScheduleExpert.vue";
import ScheduleProfessional from "@/views/Certifications/assets/ScheduleProfessional.vue";

export default defineComponent({
  name: "ScheduleLevels",
  components: {
    ScheduleEntryAssociate,
    ScheduleExpert,
    ScheduleProfessional,
  },
  props: {
    certification: {
      type: Object,
      required: true,
    },
    topics: {
      type: Array,
      default: () => [],
    },
    currentExam: {
      type: Object,
      default: null,
    },
    exCardConcentration: {
      type: Object,
      default: null,
    },
    exCardCore: {
      type: Object,
      default: null,
    },
  },
});
</script>
<template>
  <ScheduleEntryAssociate
    data-cy="schedule-entry-associate"
    v-if="
      certification.certification_level === 'Associate' ||
      certification.certification_level === 'Entry'
    "
    :certification="certification"
    :topics="topics"
  />

  <ScheduleProfessional
    data-cy="schedule-professional"
    v-else-if="certification.certification_level === 'Professional'"
    :certification="certification"
    :topics="topics"
    :current-exam="currentExam"
    :ex-card-concentration="exCardConcentration"
  />

  <ScheduleExpert
    data-cy="schedule-expert"
    v-else-if="certification.certification_level === 'Expert'"
    :certification="certification"
    :topics="topics"
    :ex-card-core="exCardCore"
  />
</template>
