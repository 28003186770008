import { z } from "zod";

import {
  processQueryResponse,
  processUserRequest,
} from "@/services/genAiService/types";
import http from "@/services/http";

export type ProcessQueryRequest = z.infer<typeof processUserRequest>;

export default async function processQuery(postRequest: ProcessQueryRequest) {
  processUserRequest.parse(postRequest);

  const { data } = await http().post(
    `${window.env.GEN_AI_API_LOCATION}/process_query`,
    postRequest
  );

  return processQueryResponse.parse(data);
}
