import { z } from "zod";

import http from "@/services/http";

const action = z.object({
  action_id: z.number(),
  guid: z.string(),
  element: z.string(),
  action: z.object({
    status: z.string(),
  }),
});

const userActionsResponse = z.object({
  actions: z.array(action),
  user_id: z.number(),
});

const updateUserActionsParams = z.object({
  action: z.string(),
  element: z.string(),
  guid: z.string(),
});

export type UpdateUserActionsParams = z.infer<typeof updateUserActionsParams>;

export async function fetchUserActions() {
  const { data } = await http().get(
    `${window.env.PROFILE_API_LOCATION}/user-actions`
  );
  return userActionsResponse.parse(data);
}

export function updateUserActions(params: UpdateUserActionsParams) {
  updateUserActionsParams.parse(params);
  return http().post(`${window.env.PROFILE_API_LOCATION}/user-actions`, params);
}
