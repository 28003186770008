<template>
  <div class="absolute right-0 top-[25.625rem]">
    <img
      src="@/views/Certifications/assets/images/rt-side-shape.png?url"
      alt=""
      class="w-28 lg:w-44"
    />
  </div>
  <div
    class="absolute left-0 top-[155rem] w-16 lg:top-[20] lg:h-56 lg:w-56"
    data-cy="lt-Side-Shape-logo"
  >
    <img
      src="@/views/Certifications/assets/images/lt-side-shape.png?url"
      alt=""
    />
  </div>
</template>
