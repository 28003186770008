<script lang="ts">
import { defineComponent } from "vue";
const sanitizeUrl = await import("@braintree/sanitize-url");

export default defineComponent({
  emits: ["clicked"],
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  computed: {
    sanitizedUrl() {
      return sanitizeUrl.sanitizeUrl(this.url);
    },
  },
});
</script>

<template>
  <a
    :href="sanitizedUrl"
    tabindex="0"
    target="_blank"
    @click.prevent="$emit('clicked')"
    @keyup.enter.prevent="$emit('clicked')"
    @keyup.space.prevent="$emit('clicked')"
  >
    <slot />
  </a>
</template>
