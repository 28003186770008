import { z } from "zod";

import http from "@/services/http";

const entitlementsResponse = z
  .object({
    content_bundle_id: z.number(),
    content_bundle_name: z.string(),
    entitlement_id: z.number(),
  })
  .passthrough();

const BundlesResponse = z
  .object({
    user_id: z.number(),
    entitlements: z.array(entitlementsResponse),
  })
  .passthrough();

const userStatus = z
  .object({
    bundles: BundlesResponse,
    cco_id: z.string(),
    display_name: z.string(),
    loginCount: z.number(),
    surveyCompleted: z.boolean(),
    userId: z.number(),
    userInitials: z.string(),
    "user-roles": z.array(z.any()),
    userEntitlements: z.array(z.string()),
    email: z.string(),
  })
  .passthrough();

export async function fetchUserStatus() {
  if (!window.env.PROFILE_API_LOCATION) {
    return;
  }
  const { data } = await http().get(
    `${window.env.PROFILE_API_LOCATION}/userStatus`
  );

  return userStatus.parse(data);
}
