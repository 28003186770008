/**
 * getDuration - common duration wrapper
 */
// Returns the duration of a run in hours and minutes

export default function getDuration(x: any): string {
  const secondsPerHour = 3600;
  const secondsPerMinute = 60;

  let duration;
  if (x?.duration_sec) {
    duration = x.duration_sec;
  } else if (x?.duration) {
    duration = x.duration;
  } else {
    duration = x;
  }

  const hours = Math.floor(duration / secondsPerHour);
  const minutes = Math.floor((duration % secondsPerHour) / secondsPerMinute);

  const hoursDisplay = hours > 0 ? `${hours}h` : "";
  const minutesDisplay = minutes > 0 ? `${minutes}m` : "";

  return `${hoursDisplay} ${minutesDisplay}`.trim();
}
