import { createStore } from "vuex";

import content from "@/store/modules/contents";
import genAi from "@/store/modules/genAi";
import instance from "@/store/modules/instance";
import pricing from "@/store/modules/pricing";
import user from "@/store/modules/user";

const store = createStore({
  modules: {
    user,
    content,
    pricing,
    instance,
    genAi,
  },
});

export default store;
