<script lang="ts">
import { defineComponent } from "vue";

import nav from "@/layout/Header/nav.json";
import logoLink from "@/layout/LogoLink.vue";

export default defineComponent({
  components: { logoLink },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    navigationList() {
      let navigation = nav;
      const { LEARN_PAGE_FLAG, ENABLE_TOPICS_DISCOVERY } = window.env;

      if (!LEARN_PAGE_FLAG || !ENABLE_TOPICS_DISCOVERY) {
        navigation = navigation.filter((navItem) => navItem.title !== "Learn");
      }

      if (ENABLE_TOPICS_DISCOVERY) {
        navigation = navigation.filter(
          (navItem) => navItem.title !== "Explore"
        );
      }

      // nav, but remove the last item
      return navigation.slice(0, -1);
    },
  },
});
</script>

<template>
  <div class="flex items-center justify-start">
    <logo-link
      :dark="dark"
      logo-link-tag-header="logo-link-header"
      class="text-29"
    />

    <ul
      class="border-black-dark-light ml-4 flex items-center justify-start gap-4 space-x-2 py-5 text-16 font-light lg:py-0 xl:ml-10 xl:gap-8 xxl:ml-20 xxl:text-22"
    >
      <li v-for="navitem in navigationList" :key="navitem.title">
        <router-link
          tabindex="0"
          :data-cy="!dark ? navitem.navCy : `${navitem.navCy}-dark`"
          active-class="underline"
          class="inline-block whitespace-nowrap hover:underline focus:underline focus-visible:ring-2"
          :to="{ name: navitem.route }"
          >{{ navitem.title }}</router-link
        >
      </li>
    </ul>
  </div>
</template>
