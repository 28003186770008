import genAiService from "@/services/genAiService";
import type { AiAssistantResponseResult } from "@/services/genAiService/types";
export default {
  namespaced: true,
  state: {
    queryResults: [] as AiAssistantResponseResult[],
  },
  getters: {
    queryResults: (state: any) => state.queryResults,
  },
  mutations: {
    emptyQueryResults(state: any) {
      state.queryResults = [];
    },
    setQueryResults(state: any, result: AiAssistantResponseResult) {
      state.queryResults.push(result);
    },
  },
  actions: {
    async processQuery({ commit }: any, queryObj: any) {
      await genAiService
        .processQuery(queryObj)
        .then((data) => {
          let result: AiAssistantResponseResult;

          if (data) {
            if (typeof data === "string") {
              result = {
                answer: data,
                type: "error",
                user_query: queryObj.user_query,
              };
              commit("setQueryResults", result);
            } else {
              result = {
                ...data,
                type: "success",
              };
              commit("setQueryResults", result);
            }
          }
        })
        .catch((e: unknown) => {
          let err: string = "";

          if (typeof e === "string") {
            err = e;
          } else if (e instanceof Error) {
            err = e.message;
          } else {
            err = "Something went wrong.";
          }

          const result: AiAssistantResponseResult = {
            answer: err,
            type: "error",
            user_query: queryObj.user_query,
          };

          commit("setQueryResults", result);

          throw e;
        });
    },
  },
};
