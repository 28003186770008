<script lang="ts">
import { useHead } from "unhead";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    srcset: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    sizes: {
      type: String,
      default: "",
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    preload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isInViewPort: false,
    };
  },
  mounted() {
    if (this.preload) {
      this.preloadImages();
    }
    this.loadImages();
  },
  methods: {
    loadImages() {
      // if lazy is true then use IntersectionObserver to lazy load the image
      if (this.lazy) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.isInViewPort = true;
              observer.disconnect();
            }
          });
        });
        observer.observe(this.$el);
      }
      // if lazy is false then set isInViewPort to true
      if (!this.lazy) {
        this.isInViewPort = true;
      }
    },
    preloadImages() {
      // if srcset isn't empty then use useHeadSafe to preload the images
      if (this.srcset !== "") {
        useHead({
          link: [
            {
              rel: "preload",
              as: "image",
              href: this.src,
              imagesrcset: this.srcset,
              imagesizes: this.sizes,
            },
          ],
        });
      }

      // if srcset is empty then use useHeadSafe to preload the image
      if (this.srcset === "") {
        useHead({
          link: [
            {
              rel: "preload",
              as: "image",
              href: this.src,
            },
          ],
        });
      }
    },
    onImageLoad() {
      this.isInViewPort = true;
    },
    onImageError() {
      if (import.meta.env.DEV && !import.meta.env.TEST) {
        console.log("Error loading image");
      }
    },
  },
});
</script>
<template>
  <div class="lazy-image">
    <img
      v-if="isInViewPort && srcset !== '' && lazy"
      :src="src"
      :alt="alt"
      :srcset="srcset"
      :width="width"
      :height="height"
      @load="onImageLoad"
      @error="onImageError"
    />
    <img
      v-else-if="isInViewPort && srcset !== '' && !lazy"
      :src="src"
      :alt="alt"
      :srcset="srcset"
      :width="width"
      :height="height"
      fetchpriority="high"
      @load="onImageLoad"
      @error="onImageError"
    />
    <img
      v-else-if="isInViewPort"
      :src="src"
      :alt="alt"
      :width="width"
      :height="height"
      @load="onImageLoad"
      @error="onImageError"
    />
    <div v-else class="placeholder" :style="`height: ${height}px`"></div>
  </div>
</template>
