import { retrieveAllCatalogItems } from "@/services/catalogService/courses";

async function fetchNonPathData(myLearningList: any[]) {
  const nonPathArr = mapNonPath(myLearningList);
  if (nonPathArr.guidList.length || nonPathArr.idList.length) {
    let nonPathData: any = null;
    await retrieveAllCatalogItems(nonPathArr.guidList, nonPathArr.idList, "")
      .then((data) => {
        if (data?.length) {
          nonPathData = data;
        } else {
          nonPathData = [];
        }
      })
      .catch(() => {
        // do nothing
      });
    return nonPathData;
  }
  return [];
}

async function fetchPathData(myLearningList: any[]) {
  const pathInfo = mapPath(myLearningList);
  if (pathInfo.guidList.length || pathInfo.idList.length) {
    let pathData: any = null;
    await retrieveAllCatalogItems(pathInfo.guidList, pathInfo.idList, "path")
      .then((data) => {
        if (data?.length) {
          pathData = data;
        } else {
          pathData = [];
        }
      })
      .catch(() => {
        // do nothing
      });
    return pathData;
  }
  return [];
}

function mapNonPath(myLearningList: any[]) {
  const nonPathList = myLearningList
    .map((item: any) =>
      item.type !== "path" &&
      item.catalog_data == null &&
      item.track_data == null
        ? item
        : null
    )
    .filter((item: any) => item != null);

  const nonPathIDList: number[] = [];
  const nonPathGUIDList: string[] = [];
  nonPathList.forEach((item: any) => {
    if (item.guid) {
      nonPathGUIDList.push(item.guid);
    } else {
      nonPathIDList.push(item.content_id);
    }
  });

  return {
    idList: nonPathIDList,
    guidList: nonPathGUIDList,
  };
}

function mapPath(myLearningList: any[]) {
  const pathList = myLearningList
    .map((item: any) =>
      item.type === "path" && item.track_data === undefined ? item : null
    )
    .filter((item: any) => item != null);

  const pathIDList: number[] = [];
  const pathGUIDList: string[] = [];
  pathList.forEach((item: any) => {
    if (item.guid) {
      pathGUIDList.push(item.guid);
    } else {
      pathIDList.push(item.path_id);
    }
  });

  return {
    idList: pathIDList,
    guidList: pathGUIDList,
  };
}

export default async function handleLearningCatalog(myLearningList: any[]) {
  if (!myLearningList?.length) return;

  let missingData: any[] = [];

  const promises = [
    fetchNonPathData(myLearningList),
    fetchPathData(myLearningList),
  ];

  await Promise.allSettled(promises)
    .then((dataArr) => {
      dataArr?.forEach((data: any) => {
        if (data != null) {
          missingData = [...missingData, ...data.value];
        }
      });
    })
    .catch(() => {
      // do nothing
    });

  return missingData;
}
