<script lang="ts">
import "@cisco-u/tags/cu-tags.js";
import "@cisco-u/links-external/cu-links-external.js";

import { defineComponent } from "vue";

import { sendLinkCtaTelemetry } from "@/utils/ctaTelemetry";
import isUnauth from "@/utils/isUnauth";

export default defineComponent({
  props: {
    certification: {
      type: Object,
      required: true,
    },
    topics: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isUnauth,
    async sendUnauthTelemetry(title: string) {
      if (this.isUnauth()) {
        await sendLinkCtaTelemetry(this.$route.fullPath, title, true);
      }
    },
  },
});
</script>
<template>
  <div class="px-4 pb-20 lg:px-44" data-cy="associate-schedule">
    <div class="mb-2 mt-5 text-32 text-black-light">
      <span id="schedule" class="scroll-mt-28">Schedule the exam</span>
    </div>
    <p class="text-16">Time to get certified! Schedule and pass your exam.</p>
    <div
      :class="['flex flex-col lg:flex-row', { 'mt-4': isUnauth() }]"
      v-if="certification?.exam?.length > 0"
    >
      <div class="mx-auto mt-10 w-[35%] lg:mx-0">
        <img
          src="@/views/Certifications/assets/images/schedule-cert-exam-img.png?url"
          alt="Schedule the Exam"
        />
      </div>
      <div
        v-for="(exams, index) in certification.exam"
        :key="exams.id"
        :class="[
          'mt-10 flex w-full flex-col justify-between px-4 pb-4 lg:w-1/2 lg:pl-20',
          {
            'border-l-2': index % 2,
          },
        ]"
        data-cy="core-exam-info"
      >
        <div
          :class="[
            'min-h-14 text-30',
            { 'mb-4': isUnauth(), 'mb-12': !isUnauth() },
          ]"
          data-cy="exam-number"
        >
          {{ exams.exam_number + " " + exams.acronym }}
        </div>
        <div class="text-12">TIME</div>
        <div class="mb-10 min-h-12 text-30" data-cy="exam-duration">
          {{ exams.duration_sec / 60 }} min
        </div>
        <div
          :class="['text-12', { 'font-normal tracking-md': isUnauth() }]"
          v-if="topics.length > 0"
        >
          TOPICS COVERED
        </div>
        <div
          :class="['gap mt-5 flex flex-row flex-wrap', { 'pb-4': !isUnauth() }]"
        >
          <div
            :class="['flex flex-row flex-wrap gap-2', { 'pb-12': !isUnauth() }]"
          >
            <div v-for="topic in topics" :key="topic as string">
              <cu-tags :text="topic"></cu-tags>
            </div>
          </div>
        </div>
        <cu-links-external
          type="line-diagonal"
          text="Schedule exam"
          @click="sendUnauthTelemetry('Go to CertMetrics')"
          linkTitle="Go to CertMetrics"
          url="https://cp.certmetrics.com/cisco/en/schedule/schedule-exam"
          noUnderline="true"
          color="blue-light"
          class="mt-6"
        ></cu-links-external>
      </div>
    </div>
  </div>
</template>
