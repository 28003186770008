<script lang="ts">
import "@cisco-u/icons/icons/cu-icon-chevron-right.js";
import "@cisco-u/links-external/cu-links-external.js";

import { defineComponent } from "vue";

import learningPathResult from "@/components/contentBits/learningPathResult.vue";
import practiceExamResult from "@/components/contentBits/practiceExamResult.vue";
import { sendLinkCtaTelemetry } from "@/utils/ctaTelemetry";
import isUnauth from "@/utils/isUnauth";

export default defineComponent({
  components: {
    learningPathResult,
    practiceExamResult,
  },
  props: {
    exCardCore: {
      type: Object,
      default: null,
    },
    exCardEntry: {
      type: Object,
      default: null,
    },
    exCardLab: {
      type: Object,
      default: null,
    },
    status: {
      type: Object,
      rdefault: null,
    },
    communities: {
      type: Object,
      default: null,
    },
    recommendedPaths: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showPathsOfExam(): any {
      let result: any = [];
      if (!this.exCardCore?.prescribed_prep_material?.length) return result;
      this.exCardCore.prescribed_prep_material?.forEach((material: any) => {
        let path = this.recommendedPaths?.find(
          (path: any) => path.guid == material.guid
        );
        if (path) {
          result = result.concat(path);
        }
      });
      return result;
    },
    showPathsOfLab(): any {
      let result: any = [];
      if (!this.exCardLab?.prescribed_prep_material?.length) return result;
      //eslint-disable-next-line
      this.exCardLab.prescribed_prep_material?.forEach((material: any) => {
        let path = this.recommendedPaths?.find(
          (path: any) => path.guid == material.guid
        );
        if (path) {
          result = result.concat(path);
        }
      });
      return result;
    },
  },
  methods: {
    isUnauth,
    async sendUnauthTelemetry(title: string) {
      if (this.isUnauth()) {
        await sendLinkCtaTelemetry(this.$route.fullPath, title, true);
      }
    },
  },
});
</script>

<template>
  <div class="border-b-gray px-4 pb-20 lg:px-44" data-cy="expert-prepare">
    <div class="mt-5 text-41 text-black-light">
      <span id="prepare" class="scroll-mt-28">Prepare</span>
    </div>
    <p :class="['text-18', { 'pt-3.5': isUnauth() }]">
      To earn your certification, you must pass the core exam and lab exam.
    </p>

    <div
      class="my-10 h-[3.125rem] text-22 font-bold text-black"
      data-cy="step1-title"
    >
      Step 1: Learning and practice for the core exam
    </div>
    <div v-if="exCardCore?.prescribed_prep_material?.length > 0">
      <p class="mb-5 text-18">
        Use the following resources designed to help you pass your core exam.
      </p>
      <div class="flex-row flex-wrap lg:flex" data-cy="expert-step1">
        <div
          class="flex-row lg:flex"
          v-for="path in showPathsOfExam"
          :key="path"
        >
          <learning-path-result :result="path" :status="status" class="pb-4" />
          <div class="mb-4 ml-5">
            <practice-exam-result
              v-if="exCardCore?.practiceExam"
              tabindex="0"
              :result="exCardCore.practiceExam"
              :status="status"
            />
          </div>
          <div class="schedule mt-10 lg:ml-5">
            <p class="text-30">Schedule the core exam</p>
            <p
              :class="[
                'mb-7 text-18',
                { 'mt-5 text-black-lightest': isUnauth() },
              ]"
            >
              Feeling ready? <br />If so, you've got this!
            </p>
            <cu-links-external
              type="line-diagonal"
              @click="sendUnauthTelemetry('Go to CertMetrics')"
              :text="'Schedule the ' + exCardCore.exam_number + ' exam today'"
              url="https://cp.certmetrics.com/cisco/en/schedule/schedule-exam"
              linkTitle="Go to CertMetrics"
              noUnderline="true"
              color="blue-light"
              class="mt-6"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-1/2 text-18">
      If you can't find any Cisco U. training resources,
      <cu-links-external
        v-if="communities?.URL"
        @click="sendUnauthTelemetry('Go to Communities')"
        type="line-diagonal"
        text="join a community"
        :url="communities.URL"
        linkTitle="Go to Communities"
        noUnderline="true"
        color="blue-light"
        class="mt-6"
      />
      on the Cisco Learning Network to connect with certification experts and
      get learning guidance from your peers.
      <div class="schedule mt-10 lg:ml-5">
        <p class="text-30">Schedule Core Exam</p>
        <p
          :class="['mb-7 text-18', { 'mt-5 text-black-lightest': isUnauth() }]"
        >
          Feeling ready? <br />
          If so, you've got this!
        </p>
        <cu-links-external
          v-if="exCardEntry?.exam_number"
          type="line-diagonal"
          @click="sendUnauthTelemetry('Go to CertMetrics')"
          :text="
            'Schedule the ' +
            exCardEntry.exam_number +
            ' ' +
            exCardEntry.acronym +
            ' exam today'
          "
          url="https://cp.certmetrics.com/cisco/en/schedule/schedule-exam"
          linkTitle="Go to CertMetrics"
          noUnderline="true"
          color="blue-light"
          class="mt-6"
        />
      </div>
    </div>

    <div class="mb-4 mt-10 text-22 font-bold text-black" data-cy="step2-title">
      Step 2: Learning and practice for the lab exam
    </div>

    <div v-if="exCardLab?.prescribed_prep_material?.length > 0">
      <p class="mb-5 text-18">
        Use the following resources designed to help you pass your lab exam.
      </p>
      <div
        class="flex-row flex-wrap lg:flex"
        v-for="path in showPathsOfLab"
        :key="path"
      >
        <learning-path-result :result="path" :status="status" class="pb-4" />
        <div class="mb-4 ml-5">
          <practice-exam-result
            v-if="exCardLab.practiceExam"
            tabindex="0"
            :result="exCardLab.practiceExam"
            :status="status"
          />
        </div>
        <div class="schedule mt-10 lg:ml-5">
          <p class="text-30">Schedule the lab exam</p>
          <p
            :class="[
              'mb-7 text-18',
              { 'mt-5 text-black-lightest': isUnauth() },
            ]"
          >
            Feeling confident? <br />If so, now is the time to reserve your
            spot.
          </p>
          <cu-links-external
            type="line-diagonal"
            @click="sendUnauthTelemetry('Go to lab exam scheduling tool')"
            :text="'Schedule ' + exCardLab.acronym + ' exam today!'"
            url="https://ccie.cloudapps.cisco.com/CCIE/Schedule_Lab/CCIEOnline/CCIEOnline"
            linkTitle="Go to lab exam scheduling tool"
            noUnderline="true"
            color="blue-light"
            class="mt-6"
          />
        </div>
      </div>
    </div>

    <div v-else class="text-18" data-cy="expert-step2">
      If you can't find any Cisco U. training resources,
      <cu-links-external
        v-if="communities?.URL"
        @click="sendUnauthTelemetry('Go to Communities')"
        type="line-diagonal"
        text="join a community"
        :url="communities.URL"
        linkTitle="Go to Communities"
        noUnderline="true"
        color="blue-light"
        class="mt-6"
      />
      on the Cisco Learning Network to connect with certification experts and
      get learning guidance from your peers.
      <div class="schedule mt-10 lg:ml-5">
        <p class="text-30">Schedule Lab Exam</p>
        <p
          :class="['mb-7 text-18', { 'mt-5 text-black-lightest': isUnauth() }]"
        >
          Feeling ready? <br />
          If so, you've got this!
        </p>
        <cu-links-external
          v-if="exCardLab?.exam_number"
          type="line-diagonal"
          :text="
            'Schedule the ' +
            exCardLab.exam_number +
            ' ' +
            exCardLab.acronym +
            ' exam today'
          "
          url="https://ccie.cloudapps.cisco.com/CCIE/Schedule_Lab/CCIEOnline/CCIEOnline"
          linkTitle="Go to lab exam scheduling tool"
          noUnderline="true"
          color="blue-light"
          class="mt-6"
        ></cu-links-external>
      </div>
    </div>
  </div>
</template>
