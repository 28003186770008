import { useHead } from "unhead";

import type { SchemaJsonld } from "@/utils/schemaJsonld";
import schemaJsonld from "@/utils/schemaJsonld";

function parseSchema(result: any): SchemaJsonld {
  const schema = schemaJsonld(result);
  // now iterate over results removing any empty strings, nulls, or undefined
  for (const [key, value] of Object.entries(schema)) {
    if (value === "" || value === null || value === undefined) {
      delete schema[key as keyof typeof schema];
    }
  }

  return schema;
}

function parseCourses(result: any): SchemaJsonld[] {
  return result.Courses.map((course: any) => {
    return parseSchema(course);
  });
}

function parseTracks(result: any): SchemaJsonld[] {
  return result.tracks.map((track: any) => {
    // get base schema of track
    const trackSchema = parseSchema(track);
    // if track.Courses then add as hasPart
    if (track?.Courses) {
      trackSchema.hasPart = parseCourses(track);
    }
    return trackSchema;
  });
}

function attachToHead(schema: SchemaJsonld) {
  // add the schema to the head
  const head = useHead({});
  head?.patch({
    script: [
      {
        type: "application/ld+json",
        key: "content-json-ld",
        innerHTML: JSON.stringify(schema),
      },
    ],
  });
}

export default function schemaCompile(result: any) {
  // create the base schema
  const schema = parseSchema(result);

  // if result.tracks add as hasPart else if result.Courses add as hasPart
  if (result?.tracks) {
    schema.hasPart = parseTracks(result);
  } else if (result?.Courses) {
    schema.hasPart = parseCourses(result);
  }

  // add the schema to the head
  attachToHead(schema);

  return schema;
}
