<script lang="ts">
import { defineComponent } from "vue";

import isUnauth from "@/utils/isUnauth";
import telemetry from "@/utils/telemetry";

export default defineComponent({
  props: {
    threshold: {
      type: Number,
      default: 200,
    },
    telemSend: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      captured: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    isUnauth,
    handleScroll() {
      if (
        this.telemSend != "" &&
        this.$refs &&
        this.$refs.belowFold &&
        (this.$refs.belowFold as HTMLElement).getBoundingClientRect().top <
          this.threshold &&
        !this.captured
      ) {
        telemetry.belowTheFold(this.telemSend, this.isUnauth());
        this.captured = true;
      }
    },
  },
});
</script>

<template>
  <div ref="belowFold">
    <slot />
  </div>
</template>
