<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    value: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <button
    tabindex="0"
    @click.stop="$emit('click')"
    @keyup.enter.stop="$emit('click', value)"
    @keyup.space.stop="$emit('click', value)"
    :value="value"
    :class="[
      'btn inline-flex items-center bg-transparent px-4 py-2 text-sm shadow-md transition duration-150 ease-out hover:border-blue-700 hover:bg-blue-700 hover:text-white hover:shadow-lg focus:outline-none focus:ring-0 active:bg-blue active:shadow-lg',
      {
        active: active,
      },
    ]"
  >
    <slot />
  </button>
</template>
