import { z } from "zod";

import http from "@/services/http";

const productsPricing = z
  .object({
    productGuid: z.number(),
    name: z.string(),
    price: z.number(),
    guid: z.string(),
    is_single: z.boolean(),
    discount: z.number(),
    discount_price: z.number(),
    acronym: z.string(),
    version: z.string(),
    clc_count: z.number(),
    subscription_length_days: z.number(),
    content_bundle_id: z.array(z.number()),
    eosDate: z.string().datetime().nullish(),
  })
  .strict();

const contents = z
  .object({
    id: z.number(),
    guid: z.string(),
    name: z.string(),
    type: z.string(),
    url: z.string().nullish(),
  })
  .strict();

const contentBundle = z
  .object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    guid: z.string(),
    is_single: z.number(),
    active: z.number(),
    content_count: z.number(),
    created_at: z.string().datetime().nullish(),
    updated_at: z.string().datetime().nullish(),
    deleted_at: z.string().datetime().nullish(),
    contents: z.array(contents),
  })
  .strict();

const contentBundleResponse = z.array(contentBundle);

const productsPricingResponse = z.array(productsPricing);

export async function productPricing() {
  const { data } = await http().get(
    `${window.env.CATALOG_API_LOCATION}/productsPricing`
  );

  return productsPricingResponse.parse(data);
}

export async function fetchById(Id: number) {
  const { data } = await http().post(
    `${window.env.CATALOG_API_LOCATION}/get-content-catalog-bundle`,
    {
      content_bundle_id: [Id],
    }
  );

  return contentBundleResponse.parse(data);
}
