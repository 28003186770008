import { freeContents } from "@/services/unauthService/freeContents";

export default {
  namespaced: true,
  state: {
    contentBundles: [],
    authorizedContent: [],
  },
  getters: {
    contentBundles: (state: any) => state.contentBundles,
    freeBundle: (state: any) => {
      if (state.contentBundles.length === 0) return null;
      return state.contentBundles.filter((bundle: any) => {
        return bundle.name === "Cisco U. Free";
      })[0];
    },
    essentialsBundle: (state: any) => {
      if (state.contentBundles.length === 0) return null;
      return state.contentBundles.filter((bundle: any) => {
        return bundle.name === "Cisco U. Essentials";
      })[0];
    },
    allAccessBundle: (state: any) => {
      if (state.contentBundles.length === 0) return null;
      return state.contentBundles.filter((bundle: any) => {
        return bundle.name === "Cisco U. All Access";
      })[0];
    },
    freeId: (state: any, getters: any) => {
      if (getters.freeBundle === null || getters.freeBundle === undefined)
        return 0;
      return getters.freeBundle.id;
    },
    essentialsId: (state: any, getters: any) => {
      if (getters.essentialsBundle === null) return 0;
      return getters.essentialsBundle.id;
    },
    allAccessId: (state: any, getters: any) => {
      if (getters.allAccessBundle === null) return 0;
      return getters.allAccessBundle.id;
    },
    essentialsProductId: (state: any, getters: any) => {
      if (getters.essentialsBundle === null) return 0;
      return getters.essentialsBundle.productGuid;
    },
    allAccessProductId: (state: any, getters: any) => {
      if (getters.allAccessBundle === null) return 0;
      return getters.allAccessBundle.productGuid;
    },
    authorizedContent:
      (state: any) =>
      (guid: string): boolean => {
        if (state.authorizedContent.length === 0) return false;
        return state.authorizedContent.includes(guid);
      },
  },
  mutations: {
    setContentBundle(state: any, value: any) {
      const plans = value
        .filter((bundle: any) => {
          return !bundle.is_single;
        })
        .map((bundle: any) => {
          bundle.id = bundle.content_bundle_id[0];
          return bundle;
        });
      state.contentBundles = plans;
    },
    setAuthorizedContent(state: any, value: any) {
      state.authorizedContent = value;
    },
  },
  actions: {
    getFreeContent({ commit }: any, type: string) {
      freeContents()
        .then((response: any) => {
          if (response.userEntitlements) {
            commit("setAuthorizedContent", response.userEntitlements);
          }
        })
        .catch(() => {
          // intentional
        });
    },
  },
};
