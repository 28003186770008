import { useCookies } from "vue3-cookies";

const cookies = useCookies().cookies;

export function setRedirectUrlCookie(value: string, days: number) {
  const expiresAt = new Date();
  expiresAt.setDate(expiresAt.getDate() + days);
  cookies.set("redirectUrl", value, expiresAt);
}

export function getRedirectUrlCookie() {
  return cookies.get("redirectUrl");
}

export function deleteRedirectUrlCookie() {
  cookies.remove("redirectUrl");
}
