import http from "@/services/http";

export async function fetch() {
  const { data } = await http().get(
    `${window.env.APOLLO_MIDDLEWARE_API_LOCATION}/Communities`
  );

  return {
    communities: data,
    certificationCommunities: data.filter((community: any) => {
      return community.Category == "Professional/Expert";
    }),
    associateCommunities: data.filter((community: any) => {
      return community.Category == "Associate";
    }),
    moreCommunities: data.filter((community: any) => {
      return (
        community.Category != "Professional/Expert" &&
        community.Category != "Associate"
      );
    }),
    communitythreads: data.filter((community: any) => {
      return (
        community.Name === "Cisco Modeling Labs - Personal Community" ||
        community.Name === "CCNA"
      );
    }),
    communitytop: data.filter((community: any) => {
      return community.Name === "CCNA";
    }),
    communitybottom: data.filter((community: any) => {
      return community.Name === "Cisco Modeling Labs - Personal Community";
    }),
  };
}
