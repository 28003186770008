import { productPricing } from "@/services/catalogService/contentBundle";
import { subscriptions } from "@/services/unauthService/subscriptions";

export default {
  namespaced: true,
  state: {
    prices: [],
  },
  getters: {
    prices: (state: any) => state.prices,
    singleTitleBundles: (state: any) => {
      return state.prices.filter((price: any) => price.is_single);
    },
    singleTitleBundlesNotFree: (state: any) => {
      return state.prices.filter(
        (price: any) => price.is_single && price.price > 0
      );
    },
    bundlePrices: (state: any) => {
      return state.prices.filter((price: any) => !price.is_single);
    },
  },
  mutations: {
    setPrices(state: any, value: any) {
      state.prices = value;
    },
  },
  actions: {
    async fetchPrices({ commit }: any) {
      await productPricing()
        .then((bundles) => {
          commit("content/setContentBundle", bundles, { root: true });
          commit("setPrices", bundles);
        })
        .catch(() => {
          // do nothing
        });
    },
    async fetchSubscriptions({ commit }: any) {
      await subscriptions()
        .then((bundles) => {
          commit("content/setContentBundle", bundles, { root: true });
          commit("setPrices", bundles);
        })
        .catch(() => {
          // do nothing
        });
    },
  },
};
