import ulaLaptop1 from "@/assets/images/unauth-landing-assign-laptop.png?url";
import ulaLaptop2 from "@/assets/images/unauth-landing-assign-laptop-2x.png?url";
import ulaLaptop3 from "@/assets/images/unauth-landing-assign-laptop-3x.png?url";
import uleLaptop1 from "@/assets/images/unauth-landing-exam-laptop.png?url";
import uleLaptop2 from "@/assets/images/unauth-landing-exam-laptop-2x.png?url";
import uleLaptop3 from "@/assets/images/unauth-landing-exam-laptop-3x.png?url";
import unauthLandingHeading1 from "@/assets/images/unauth-landing-heading.png?url";
import unauthLandingHeading2 from "@/assets/images/unauth-landing-heading-2x.png?url";
import unauthLandingHeading3 from "@/assets/images/unauth-landing-heading-3x.png?url";
import ulLaptop1 from "@/assets/images/unauth-landing-laptop.png?url";
import ulLaptop2 from "@/assets/images/unauth-landing-laptop-2x.png?url";
import ulLaptop3 from "@/assets/images/unauth-landing-laptop-3x.png?url";
import ullLaptop1 from "@/assets/images/unauth-landing-live-laptop.png?url";
import ullLaptop2 from "@/assets/images/unauth-landing-live-laptop-2x.png?url";
import ullLaptop3 from "@/assets/images/unauth-landing-live-laptop-3x.png?url";
import unauthLandingManage1 from "@/assets/images/unauth-landing-manage.png?url";
import unauthLandingManage2 from "@/assets/images/unauth-landing-manage-2x.png?url";
import unauthLandingManage3 from "@/assets/images/unauth-landing-manage-3x.png?url";
import ulPeople1 from "@/assets/images/unauth-landing-people.png?url";
import ulPeople2 from "@/assets/images/unauth-landing-people-2x.png?url";
import ulPeople3 from "@/assets/images/unauth-landing-people-3x.png?url";
import ulpLaptop1 from "@/assets/images/unauth-landing-profile-laptop.png?url";
import ulpLaptop2 from "@/assets/images/unauth-landing-profile-laptop-2x.png?url";
import ulpLaptop3 from "@/assets/images/unauth-landing-profile-laptop-3x.png?url";
import testimonialBannerA1 from "@/assets/images/unauth-testimonial-banner-1.png?url";
import testimonialBannerA2 from "@/assets/images/unauth-testimonial-banner-1-2x.png?url";
import testimonialBannerA3 from "@/assets/images/unauth-testimonial-banner-1-3x.png?url";
import TestimonialBannerB1 from "@/assets/images/unauth-testimonial-banner-2.png?url";
import TestimonialBannerB2 from "@/assets/images/unauth-testimonial-banner-2-2x.png?url";
import TestimonialBannerB3 from "@/assets/images/unauth-testimonial-banner-2-3x.png?url";
import testimonialBannerC1 from "@/assets/images/unauth-testimonial-banner-3.png?url";
import testimonialBannerC2 from "@/assets/images/unauth-testimonial-banner-3-2x.png?url";
import testimonialBannerC3 from "@/assets/images/unauth-testimonial-banner-3-3x.png?url";

const testimonialBannerCSrcSet = `${testimonialBannerC1} 1x, ${testimonialBannerC2} 2x, ${testimonialBannerC3} 3x`;
const testimonialBannerBSrcSet = `${TestimonialBannerB1} 1x, ${TestimonialBannerB2} 2x, ${TestimonialBannerB3} 3x`;
const testimonialBannerASrcSet = `${testimonialBannerA1} 1x, ${testimonialBannerA2} 2x, ${testimonialBannerA3} 3x`;
const ulaLaptopSrcSet = `${ulaLaptop1} 1x, ${ulaLaptop2} 2x, ${ulaLaptop3} 3x`;
const uleLaptopSrcSet = `${uleLaptop1} 1x, ${uleLaptop2} 2x, ${uleLaptop3} 3x`;
const unauthLandingHeadingSrcSet = `${unauthLandingHeading1} 1x, ${unauthLandingHeading2} 2x, ${unauthLandingHeading3} 3x`;
const ulLaptopSrcSet = `${ulLaptop1} 1x, ${ulLaptop2} 2x, ${ulLaptop3} 3x`;
const ullLaptopSrcSet = `${ullLaptop1} 1x, ${ullLaptop2} 2x, ${ullLaptop3} 3x`;
const unauthLandingManageSrcSet = `${unauthLandingManage1} 1x, ${unauthLandingManage2} 2x, ${unauthLandingManage3} 3x`;
const ulPeopleSrcSet = `${ulPeople1} 1x, ${ulPeople2} 2x, ${ulPeople3} 3x`;
const ulpLaptopSrcSet = `${ulpLaptop1} 1x, ${ulpLaptop2} 2x, ${ulpLaptop3} 3x`;

export default {
  ulaLaptop1,
  ulaLaptopSrcSet,
  uleLaptop1,
  uleLaptopSrcSet,
  unauthLandingHeading1,
  unauthLandingHeadingSrcSet,
  ulLaptop1,
  ulLaptopSrcSet,
  ullLaptop1,
  ullLaptopSrcSet,
  unauthLandingManage1,
  unauthLandingManageSrcSet,
  ulPeople1,
  ulPeopleSrcSet,
  ulpLaptop1,
  ulpLaptopSrcSet,
  testimonialBannerA1,
  testimonialBannerASrcSet,
  TestimonialBannerB1,
  testimonialBannerBSrcSet,
  testimonialBannerC1,
  testimonialBannerCSrcSet,
};
