import { z } from "zod";

import http from "@/services/http";

const addUserHistoryRequest = z.object({
  content_id: z.any(),
  type: z.string(),
  accessed: z.string().datetime(),
});

export type AddUserHistoryRequest = z.infer<typeof addUserHistoryRequest>;

export async function addUserHistory(postRequest: AddUserHistoryRequest) {
  addUserHistoryRequest.parse(postRequest);
  await http().post(
    `${window.env.PROFILE_API_LOCATION}/addUserHistory`,
    postRequest
  );
}
