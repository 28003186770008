import { z } from "zod";

import http from "@/services/http";

const classRegisterRequest = z.object({
  instanceId: z.string(),
  parentOrderId: z.string(),
});

export type ClassRegisterRequest = z.infer<typeof classRegisterRequest>;

export async function fetchUpcomingClasses(classID: number) {
  const { data } = await http().get(
    window.env.APOLLO_MIDDLEWARE_API_LOCATION +
      "/upcoming-user-classes/" +
      classID
  );
  return data;
}

export async function registerClasses(request: ClassRegisterRequest) {
  classRegisterRequest.parse(request);
  const { data } = await http().post(
    window.env.APOLLO_MIDDLEWARE_API_LOCATION + "/register-learner",
    request
  );
  return data;
}

export async function unRegisterClasses(instanceId: any) {
  const { data } = await http().get(
    window.env.APOLLO_MIDDLEWARE_API_LOCATION + "/unregister-user/" + instanceId
  );
  return data;
}

export async function getOrderId(request: any) {
  const { data } = await http().get(
    window.env.DIGITAL_ENTITLEMENT_API +
      "?includeBundleInfo=true&content_bundle=" +
      request.content_bundle
  );
  return data;
}

export async function getAllEntitlements() {
  const { data } = await http().get(
    window.env.DIGITAL_ENTITLEMENT_API +
      "?includeBundleInfo=true&activeEntitlements=false"
  );
  return data;
}

export async function getUpcomingClasses() {
  const { data } = await http().get(
    window.env.APOLLO_MIDDLEWARE_API_LOCATION + "/instances?days=30"
  );
  return data;
}
