import { z } from "zod";

import http from "@/services/http";

const answerOption = z.object({
  id: z.number().optional(),
  value: z.string().optional(),
  created_date: z.string().datetime().optional(),
});

const questionOption = z.object({
  id: z.number(),
  name: z.string().optional(),
  value: z.string(),
});

const questionsOptions = z.object({
  questionId: z.number(),
  telemetry_keyword: z.string(),
  answers: z.array(answerOption).optional(),
});

const questions = z.object({
  id: z.number(),
  name: z.string(),
  title: z.string(),
  category: z.string(),
  telemetry_keyword: z.string(),
  type: z.string(),
  options: z.array(questionOption),
});

const surveyDetails = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string(),
  questions: z.array(questions),
});

const surveys = z.array(surveyDetails);

const surveyResponse = z.object({
  surveyId: z.number(),
  userId: z.number(),
  questions: z.array(questionsOptions),
});

const updateSurveyParams = z.object({
  userId: z.string(),
  surveyId: z.number(),
  questions: z.array(
    z
      .object({
        questionId: z.number(),
        answers: z.string(),
      })
      .optional()
  ),
});

export type UpdateSurveyParams = z.infer<typeof updateSurveyParams>;

export async function fetchOnboardingSurvey() {
  if (!window.env.ONBOARDING_SURVEY) return Promise.resolve({});
  const { data } = await http().get(
    `${window.env.PROFILE_API_LOCATION}/surveys?title=${window.env.ONBOARDING_SURVEY}`
  );

  return surveys.parse(data);
}

export async function fetchOnboardingSurveyResponse() {
  const { data } = await http().get(
    `${window.env.PROFILE_API_LOCATION}/surveyresponse?title=${window.env.ONBOARDING_SURVEY}`
  );
  return surveyResponse.parse(data);
}

export function updateSurveyResponse(postObject: UpdateSurveyParams) {
  return http().post(
    `${window.env.PROFILE_API_LOCATION}/surveyresponse`,
    postObject
  );
}

export async function deleteResponse(surveyId: number) {
  await http().delete(
    `${window.env.PROFILE_API_LOCATION}/deleteSurvey/${surveyId}`
  );
}
