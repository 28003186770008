<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import { fetchById } from "@/services/catalogService/certifications";
import { retrieveCatalogItems } from "@/services/catalogService/courses";
import { fetch } from "@/services/middlewareService/communities";
import {
  certificationRecommendedPaths,
  getTopOfFunnelRecommendations,
} from "@/services/middlewareService/recommendations";
import schemaCompile from "@/utils/schemaCompile";
import CertificationCommunities from "@/views/Certifications/assets/CertificationCommunities.vue";
import CertificationHeader from "@/views/Certifications/assets/CertificationHeader.vue";
import CertificationOverview from "@/views/Certifications/assets/CertificationOverview.vue";
import DecorativeShapes from "@/views/Certifications/assets/DecorativeShapes.vue";
import type { ExCard } from "@/views/Certifications/assets/ExCard";
import ExploreRecommendedResources from "@/views/Certifications/assets/ExploreRecommendedResources.vue";
import ExploreResources from "@/views/Certifications/assets/ExploreResources.vue";
import type { FillExamCardOptions } from "@/views/Certifications/assets/FillExamCardOptions";
import HowDoEarn from "@/views/Certifications/assets/HowDoEarn.vue";
import PrepareLevels from "@/views/Certifications/assets/PrepareLevels.vue";
import ScheduleLevels from "@/views/Certifications/assets/ScheduleLevels.vue";

const emptyExCard: ExCard = {
  title: "",
  acronym: "",
  guid: [],
  duration_sec: 0,
  exam_number: "",
  type: "",
  prescribed_prep_material: {},
  practiceExam: null,
  topics: [],
};

export default defineComponent({
  components: {
    CertificationCommunities,
    CertificationHeader,
    DecorativeShapes,
    PrepareLevels,
    HowDoEarn,
    CertificationOverview,
    ScheduleLevels,
    ExploreResources,
    ExploreRecommendedResources,
  },
  props: {
    viewAllTag: {
      type: String,
      default: "",
    },
    viewAllLink: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      status: "user/status",
    }),
  },
  data() {
    return {
      technologies: [] as string[],
      topics: [] as string[],
      exCardEntry: emptyExCard,
      exCardCore: emptyExCard,
      exCardConcentration: emptyExCard,
      exCardLab: emptyExCard,
      recommendedPaths: [] as any,
      pathConc: [] as Array<any>,
      currentExam: {} as any,
      currentGuids: [] as string[],
      certification: {} as any,
      communities: {} as any,
      fetched: false,
      catalogData: null as any,
      title: "" as string,
      practiceExamGuids: [] as string[],
      practiceExamVal: "practice-exam" as string,
    };
  },
  methods: {
    fetchRecommended(certPreference: string) {
      getTopOfFunnelRecommendations(certPreference)
        .then((data) => {
          this.catalogData = data;
        })
        .catch(() => {
          // do nothing
        });
    },

    fetchCommunityData() {
      fetch()
        .then((data) => {
          if (data.communities) {
            data.communities.forEach((community: any) => {
              if (community.Id == this.certification.community_id) {
                this.communities = community;
              }
            });
          }
          this.fetched = true;
        })
        .catch(() => {
          // do nothing
        });
    },

    showConcentration(item: any) {
      if (item.type == "concentration") {
        this.currentExam = item;
        this.fillExamCard({
          guids: this.currentGuids,
          acronym: this.currentExam.acronym,
          duration_sec: this.currentExam.duration_sec,
          exam_number: this.currentExam.exam_number,
          title: this.currentExam.title,
          type: this.currentExam.type,
          prescribed_prep_material: this.currentExam.prescribed_prep_material,
          topics: this.currentExam.topics,
          practiceExam: this.currentExam.practiceExam,
        });
        item.prescribed_prep_material?.forEach((material: any) => {
          if (material.type == "path") {
            this.pathConc = this.recommendedPaths.find(
              (path: any) => path.guid == material.guid
            );
          }
        });
      }

      if (item.prescribed_prep_material) {
        item.prescribed_prep_material.forEach((guid: any) => {
          this.currentGuids.push(guid.guid);
        });
      }
    },

    fillExamCard({
      guids,
      acronym,
      duration_sec,
      exam_number,
      title,
      type,
      prescribed_prep_material,
      topics,
    }: FillExamCardOptions) {
      const examData = {
        guid: guids,
        acronym: acronym,
        duration_sec: duration_sec,
        exam_number: exam_number,
        title: title,
        type: type,
        practiceExam: null,
        prescribed_prep_material: prescribed_prep_material,
        topics: topics,
      };

      if (type == "core") {
        this.exCardCore = examData;
      } else if (type == "concentration") {
        this.exCardConcentration = examData;
      } else if (type == "lab") {
        this.exCardLab = examData;
      } else if (type == "entry") {
        this.exCardEntry = examData;
      }
    },

    async getCertification() {
      await fetchById(Number(this.$route.params.certificationId))
        .then((result: any) => {
          this.certification = result;
          if (!this.certification) return;
          schemaCompile(this.certification);
          this.fetchRecommended(this.certification.acronym);

          this.technologies = this.certification?.technology
            ? this.certification?.technology
            : [];
          let pathGuids: string[] = [];
          this.certification.exam?.forEach((exam: any) => {
            exam.topics.forEach((topic: any) => {
              this.topics.push(topic.topic_name);
            });

            let guids = this.fillAllExamCards(exam);
            pathGuids = pathGuids.concat(guids);
          });
          this.getPaths(pathGuids);
          this.getPracticeExams(this.practiceExamGuids);

          this.topics = this.topics.slice(0, 5);
          this.fetchCommunityData();
        })
        .catch(() => {
          this.$router.push({ name: "certifications" });
        });
    },

    fillAllExamCards(exam: any): string[] {
      let guids = [] as string[];
      switch (exam.type) {
        case "core":
          exam.prescribed_prep_material?.forEach((material: any) => {
            guids.push(material.guid);
            if (material.type == this.practiceExamVal) {
              this.practiceExamGuids.push(material.guid);
            }
          });
          break;
        case "concentration":
          this.title =
            "Select and pass the " + exam.type + " exam of your choice";
          exam.prescribed_prep_material?.forEach((mat: any) => {
            guids.push(mat.guid);
            if (mat.type == this.practiceExamVal) {
              this.practiceExamGuids.push(mat.guid);
            }
          });
          if (Object.keys(this.currentExam).length === 0) {
            this.currentExam = exam;
            exam.prescribed_prep_material.forEach((material: any) => {
              this.currentGuids.push(material.guid);
            });
          }
          break;
        case "lab":
        case "entry":
          this.title = "Pass the " + exam.type + " exam";
          exam.prescribed_prep_material?.forEach((mater: any) => {
            guids.push(mater.guid);
            if (mater.type == this.practiceExamVal) {
              this.practiceExamGuids.push(mater.guid);
            }
          });
          break;
      }
      this.fillExamCard({
        guids: guids,
        acronym: exam.acronym,
        duration_sec: exam.duration_sec,
        exam_number: exam.exam_number,
        title: exam.title,
        type: exam.type,
        prescribed_prep_material: exam.prescribed_prep_material,
        topics: exam.topics,
        practiceExam: null,
      });
      return guids;
    },
    getPaths(pathGuids: string[]) {
      certificationRecommendedPaths(pathGuids)
        .then((data) => {
          this.recommendedPaths = data.Data;
          if (Object.keys(this.pathConc).length === 0) {
            this.showConcentration(this.currentExam);
          }
        })
        .catch(() => {
          // do nothing
        });
    },
    async getPracticeExams(practiceExamGuids: string[]) {
      await retrieveCatalogItems(practiceExamGuids, this.practiceExamVal)
        .then((data) => {
          data?.map((exam: any) => {
            if (exam.exam_number == this.exCardCore.exam_number) {
              this.exCardCore.practiceExam = exam;
            } else if (exam.exam_number == this.currentExam.exam_number) {
              this.currentExam.practiceExam = exam;
            } else if (exam.exam_number == this.exCardLab.exam_number) {
              this.exCardLab.practiceExam = exam;
            } else if (exam.exam_number == this.exCardEntry.exam_number) {
              this.exCardEntry.practiceExam = exam;
            }
          });
        })
        .catch(() => {
          // do nothing
        });
    },
  },
  mounted() {
    this.getCertification();
  },
});
</script>

<template>
  <div
    class="relative mt-12 bg-white-lightest pt-20 dark:bg-black-lightest lg:pl-[4.5rem] lg:pr-4 lg:pt-36"
  >
    <DecorativeShapes />
    <div class="container mx-auto">
      <CertificationHeader
        root="Certifications"
        :location="certification.acronym"
        :level="certification.certification_level"
      />

      <CertificationOverview
        :certification="certification"
        :technologies="technologies"
        :title="title"
      />
      <HowDoEarn :certification="certification" />

      <prepare-levels
        :certification="certification"
        :exCardCore="exCardCore"
        :exCardEntry="exCardEntry"
        :exCardConcentration="exCardConcentration"
        :exCardLab="exCardLab"
        :status="status"
        :communities="communities"
        :recommendedPaths="recommendedPaths"
        :current-exam="currentExam"
        :path-conc="pathConc"
        @show-concentration="showConcentration"
      />

      <certification-communities
        v-if="certification.community_id != ''"
        :certification="certification"
        :communities="communities"
      />

      <ScheduleLevels
        :certification="certification"
        :topics="topics"
        :ex-card-core="exCardCore"
        :current-exam="currentExam"
        :ex-card-concentration="exCardConcentration"
      />

      <ExploreRecommendedResources
        v-if="catalogData && catalogData.length > 0"
        :catalogData="catalogData"
        :status="status"
      />
      <ExploreResources v-else :viewAllTag="viewAllTag" />
    </div>
  </div>
</template>
