import http from "@/services/http";

export async function products(allAccessPID: number, essentialsPID: number) {
  const { data } = await http().post(
    window.env.COMMERCE_API + "/product-promotions",
    {
      promotionType: "PRODUCT",
      productIds: [allAccessPID + "", essentialsPID + ""],
    }
  );

  const ciscoUProducts = data ?? [];
  let hasAllAccess = [];
  let hasEssentials = [];
  if (ciscoUProducts.length > 0) {
    hasAllAccess = ciscoUProducts?.filter((product: any) => {
      return product.id == allAccessPID;
    });
    hasEssentials = ciscoUProducts?.filter((product: any) => {
      return product.id == essentialsPID;
    });
  }
  let allAccess;
  let essentials;
  if (hasAllAccess.length > 0) {
    allAccess = hasAllAccess[0];
  }

  if (hasEssentials.length > 0) {
    essentials = hasEssentials[0];
  }
  return {
    ciscoUProducts,
    allAccess,
    essentials,
  };
}

export async function productTitles() {
  const { data } = await http().get(
    window.env.APOLLO_MIDDLEWARE_API_LOCATION +
      "/product-service/product-titles"
  );

  return data;
}
