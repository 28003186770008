<script lang="ts">
import DOMPurify from "dompurify";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  methods: {
    sanitizedHtml(value: string): string {
      return DOMPurify.sanitize(value, { ADD_ATTR: ["target"] });
    },
  },
});
</script>

<template>
  <div v-html="sanitizedHtml(content)"></div>
</template>
