import { createApp } from "vue";

import App from "@/App.vue";
import populateApp from "@/env/app/app";
import setSessionId from "@/env/setSessionId";

await setSessionId();

// Create the app
const app = createApp(App);
// setup app
populateApp(app);
