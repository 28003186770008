<script lang="ts">
import { defineComponent } from "vue";
const sanitizeUrl = await import("@braintree/sanitize-url");

export default defineComponent({
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  computed: {
    sanitizedUrl() {
      return sanitizeUrl.sanitizeUrl(this.url);
    },
  },
});
</script>

<template>
  <a :href="sanitizedUrl" tabindex="0" target="_blank" rel="noopener">
    <slot />
  </a>
</template>
