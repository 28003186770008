import { isEqual, isNumber, isString, uniqWith } from "lodash-es";

import { carousel } from "@/services/catalogService/courses";
import {
  add,
  fetch,
  fetchByType,
  remove,
} from "@/services/profileService/myList";
import { fetchUserProgress } from "@/services/profileService/userProgress";
import { fetchUserStatus } from "@/services/profileService/userStatus";

function isNumberTypeMatch(ele: any, content: any) {
  return (
    ele.content_id == content.id &&
    (ele.type == content.type ||
      ele.path_data?.type == content.type ||
      ele.catalog_data?.type == content.type ||
      ele.track_data?.type == content.type)
  );
}

function isNumberFilter(content: any, currentMyList: any[] = []) {
  if (content.inMyList) {
    return content.inMyList;
  }
  return (
    currentMyList.filter((ele: any) => isNumberTypeMatch(ele, content)).length >
    0
  );
}

function isStringFilter(ele: any, content: any) {
  if (ele.catalog_data) {
    return ele.catalog_data.guid == (content.id || content.guid);
  } else if (ele.path_data) {
    return ele.path_data.guid == (content.id || content.guid);
  }
  return false;
}

function returnDefaultList(ele: any, content: any) {
  return ele.content_guid && ele.catalog_data
    ? ele.catalog_data.guid == content.guid
    : false;
}

function isStandardPathTrack(content: any) {
  return (
    isString(content.id) &&
    content.type &&
    (content.type.includes("track") || content.type.includes("path"))
  );
}

function checkForGUIDs(copyItem: any, item: any) {
  return (
    (copyItem.guid && copyItem.guid === item.pathGUId) ||
    (copyItem.guid && copyItem.guid === item.courseGUID) ||
    (copyItem.guid && copyItem.guid === item.GUID) ||
    (copyItem.guid && copyItem.guid === item.catalog_data?.guid) ||
    (copyItem.guid && copyItem.guid === item.track_data?.guid) ||
    (copyItem.guid && copyItem.guid === item.guid)
  );
}

function includedInIdentifiers(copyItem: any, item: any) {
  return (
    (copyItem.path_id && copyItem.path_id === item.path_data?.id) ||
    (copyItem.path_id && copyItem.path_id === item.path_id) ||
    (copyItem.path_id && copyItem.path_id === parseInt(item.pathId)) ||
    (copyItem.content_id && copyItem.content_id === item.catalog_data?.id) ||
    checkForGUIDs(copyItem, item)
  );
}

function createItemToMerge(item: any) {
  const itemToMerge: any = {};
  itemToMerge.tags = [];

  itemToMerge.path_id =
    item.path_data?.id || item.path_id || parseInt(item.pathId) || undefined;

  itemToMerge.catalog_data = item.catalog_data;
  itemToMerge.path_data = item.path_data;
  itemToMerge.track_data = item.track_data;

  itemToMerge.title =
    item.catalog_data?.title ||
    item.path_data?.name ||
    item.track_data?.name ||
    item.title ||
    undefined;

  itemToMerge.guid =
    item.catalog_data?.guid ||
    item.path_data?.guid ||
    item.track_data?.guid ||
    item.courseGUID ||
    item.pathGUId ||
    item.GUID ||
    item.guid;

  itemToMerge.content_id =
    item.catalog_data?.id ||
    item.path_data?.id ||
    item.track_data?.id ||
    item.content_id ||
    item.exam_id ||
    undefined;

  itemToMerge.type = item.catalog_data?.type || item.type;
  itemToMerge.tags.push(item.catalog_data?.type || item.type);

  itemToMerge.percentage =
    parseFloat(item.courseCompletionPercentage) ||
    parseFloat(item.pathProgressPercentage) ||
    parseFloat(item.CompletionPercentage) ||
    parseFloat(item.percentage) ||
    undefined;

  itemToMerge.last_accessed_at =
    item.courseCompletionDate ||
    item.pathCompletionDate ||
    item.CompletionDate ||
    undefined;

  itemToMerge.is_single = item.is_single;

  itemToMerge.bookmarked = item.bookmarked;

  itemToMerge.created_at = item.created_at;

  if (item.is_single) {
    itemToMerge.tags.push("subscription");
  }

  Object.keys(itemToMerge).forEach((key) =>
    itemToMerge[key as keyof {}] === undefined
      ? delete itemToMerge[key as keyof {}]
      : {}
  );

  return itemToMerge;
}

function condenseDuplicates(myList: any[]) {
  let condensedArr: any[] = [];
  myList.forEach((item: any) => {
    const itemFound = condensedArr.some((copyItem) =>
      includedInIdentifiers(copyItem, item)
    );
    if (itemFound) {
      const duplicateItem = createItemToMerge(item);
      const index: number = condensedArr.findIndex((x) =>
        includedInIdentifiers(x, item)
      );

      condensedArr = condensedArr.map((copyItem, i) => {
        if (i === index) {
          let tags = [...new Set([...copyItem.tags, ...duplicateItem.tags])];
          const newItem = { ...copyItem, ...duplicateItem };
          if (tags.includes("course") && tags.includes("tutorial")) {
            tags = tags.filter((tag) => tag !== "course");
          }
          newItem.tags = tags;
          return newItem;
        }
        return copyItem;
      });
    } else {
      condensedArr.push(createItemToMerge(item));
    }
  });
  return sortList(condensedArr);
}

function sortList(list: any[]) {
  const sortedList = list;
  sortedList.sort((a, b) => {
    if (a.last_accessed_at !== undefined || b.last_accessed_at !== undefined) {
      const aAccessed = a.last_accessed_at;
      const bAccessed = b.last_accessed_at;

      return parseFloat(aAccessed) > parseFloat(bAccessed) ? -1 : 1;
    }

    if (a.bookmarked && b.bookmarked) {
      return a.created_at > b.created_at ? -1 : 1;
    }

    return 0;
  });
  return sortedList;
}

function findListIndex(ele: any, params: any) {
  return (
    ele.content_id == params.content_id &&
    (ele.type == params.type ||
      ele.path_data?.type == params.type ||
      ele.catalog_data?.type == params.type ||
      ele.track_data?.type == params.type)
  );
}

export default {
  namespaced: true,
  state: {
    firstTimeExploreSubscriptions: [],
    myListResults: null,
    eventResults: [],
    podcastResults: [],
    entitlements: null,
    showAnyway: false,
    showDropDown: false,
    boxShowable: false,
    adminRole: false,
    customerAdminRole: false,
    contentAdminRole: false,
    status: null,
    obSurvey: null,
    progress: null,
    surveyAnswers: null,
    achievements: null,
    myLearningFilter: [],
    filledLearningList: null,
    myNewLearningList: null,
  },
  getters: {
    firstTimeExploreSubscriptions: (state: any) =>
      state.firstTimeExploreSubscriptions,
    getSurveyAnswers: (state: any) => state.surveyAnswers,
    obSurvey: (state: any) => state.obSurvey,
    status: (state: any) => state.status,
    showAnyway: (state: any) => state.showAnyway,
    myListResults: (state: any) => state.myListResults,

    // communityLanding/IndexPage, ExploreAll, forYou, community-page
    eventResults: (state: any) => state.eventResults,
    podcastResults: (state: any) => state.podcastResults,
    entitlements: (state: any) => state.entitlements,

    boxShowable: (state: any) => state.boxShowable,
    adminRole: (state: any) => state.adminRole,
    customerAdminRole: (state: any) => state.customerAdminRole,
    contentAdminRole: (state: any) => state.contentAdminRole,

    myLearningFilter: (state: any) => state.myLearningFilter,
    filledLearningList: (state: any) => state.filledLearningList,

    inMyList:
      (state: any) =>
      (content: any): boolean => {
        if (!content) return false;
        const currentMyList = state.myListResults
          ? state.myListResults.filter((ele: any) => ele)
          : [];

        if (isNumber(content.id)) {
          return isNumberFilter(content, currentMyList);
        } else if (isStandardPathTrack(content)) {
          const ct = content.type?.includes("track") ? "track" : "path";
          return (
            currentMyList.filter(
              (ele: any) =>
                ele.content_id == parseInt(content.id) && ele.type == ct
            ).length > 0
          );
        } else if (
          isString(content.id) &&
          content.types &&
          content.types?.indexOf("track") !== -1
        ) {
          const ct = content.types?.indexOf("track") !== -1 ? "track" : "path";
          return (
            currentMyList.filter(
              (ele: any) =>
                ele.content_id == parseInt(content.content_id) && ele.type == ct
            ).length > 0
          );
        } else if (isString(content.id) || isString(content.guid)) {
          return (
            currentMyList.filter((ele: any) => isStringFilter(ele, content))
              .length > 0
          );
        }
        return (
          currentMyList.filter((ele: any) => returnDefaultList(ele, content))
            .length > 0
        );
      },
    showDropDown: (state: any) => {
      return state.showDropDown;
    },
    achievements: (state: any) => state.achievements,
    myNewLearningList: (state: any) => state.myNewLearningList,
  },
  mutations: {
    setfirstTimeExploreSubscriptions(state: any, subscriptions: any) {
      state.firstTimeExploreSubscriptions = subscriptions;
    },
    setMyLearningList(state: any, list: any) {
      const condensedArr = condenseDuplicates(list);
      state.myNewLearningList = sortList(condensedArr);
    },
    addMyLearningFilter(state: any, filter: any) {
      if (!state.myLearningFilter.includes(filter)) {
        state.myLearningFilter = [...state.myLearningFilter, filter];
      }
    },
    setMyLearningFilter(state: any, filter: any) {
      if (state.myLearningFilter.includes(filter)) {
        state.myLearningFilter = state.myLearningFilter.filter(
          (f: string) => f !== filter
        );
      } else {
        state.myLearningFilter = [...state.myLearningFilter, filter];
      }
    },
    setSurveyAnswers(state: any, answers: any) {
      state.surveyAnswers = answers;
    },
    setObSurvey(state: any, survey: any) {
      state.obSurvey = survey;
    },
    setStatus(state: any, status: any) {
      if (status) {
        state.status = status;
        const userRoleArr = status["user-roles"];
        if (userRoleArr.length > 0) {
          let isCiscoAdmin = false;
          let isCustomerAdmin = false;
          let isContentAdmin = false;
          userRoleArr.forEach((res: { roleKey: string; roleId: string }) => {
            if (res.roleKey == "cisco-admin") {
              isCiscoAdmin = true;
            }
            if (res.roleKey == "customer-admin") {
              isCustomerAdmin = true;
            }
            if (res.roleKey == "content-admin") {
              isContentAdmin = true;
            }
          });
          state.adminRole = isCiscoAdmin;
          state.customerAdminRole = isCustomerAdmin;
          state.contentAdminRole = isContentAdmin;
          state.entitlements = status.bundles?.entitlements;
        }
      }
    },
    toggleDropDown(state: any) {
      if (state.showDropDown) {
        state.showDropDown = false;
      } else {
        state.showDropDown = true;
      }
    },
    hideDropDown(state: any) {
      state.showDropDown = false;
    },
    setBoxShowable(state: any) {
      state.boxShowable = true;
    },
    setupToggleShowAnyway(state: any, showSurvey: any) {
      state.showAnyway = showSurvey;
    },
    addMyListResults(state: any, results: any) {
      if (results) {
        // Filtering results form duplications
        results = results.filter(
          (element: any) =>
            state.myListResults?.findIndex(
              (item: any) =>
                item.id === element.id && item.type === element.type
            ) == -1
        );
        // We shouldn't replace state.myListResults directly, because it will show a blink effect

        state.myListResults.push(...results);

        let myLearningResults = results.filter(
          (element: any) =>
            state.myNewLearningList?.findIndex(
              (item: any) =>
                item.content_id === element.content_id &&
                item.type === element.type
            ) == -1
        );
        myLearningResults = myLearningResults.map((item: any) => {
          item.bookmarked = true;
          return item;
        });
        const condensedArr = condenseDuplicates([
          ...state.myNewLearningList,
          ...myLearningResults,
        ]);
        state.myNewLearningList = sortList(condensedArr);
      }
    },
    setmyListResults(state: any, results: any) {
      if (results) {
        state.myListResults = results;
      }
    },
    appendmyListResults(state: any, results: any) {
      const currentResults =
        state.myListResults !== null
          ? JSON.parse(JSON.stringify(state.myListResults))
          : [];
      const newResults = currentResults
        .concat(results)
        .filter((x: any, i: any, a: any) => a.indexOf(x) == i);

      state.myListResults = uniqWith(newResults, isEqual);
    },
    removeFromMyListResults(state: any, params: any) {
      // We cant use setters here for the list because it would show a blink effect in the UI
      let index = -1; // The index of the element to delete
      if (
        params.page &&
        (params.page.toLowerCase() == "search-all" ||
          params.page.toLowerCase() == "search-type")
      ) {
        if (isNumber(params.content_id)) {
          index = state.myListResults?.findIndex((ele: any) =>
            findListIndex(ele, params)
          );
        } else {
          index = state.myListResults?.findIndex(
            (ele: any) => ele.catalog_data.guid == params.content_id
          );
        }
      } else {
        index = state.myListResults?.findIndex((ele: any) =>
          findListIndex(ele, params)
        );
      }
      if (index != -1) {
        state.myListResults?.splice(index, 1);
      }
    },
    removeFromMyLearningResults(state: any, params: any) {
      // We cant use setters here for the list because it would show a blink effect in the UI
      let index = -1; // The index of the element to delete
      if (
        params.page &&
        (params.page.toLowerCase() == "search-all" ||
          params.page.toLowerCase() == "search-type")
      ) {
        if (isNumber(params.content_id)) {
          index = state.myNewLearningList?.findIndex((ele: any) =>
            findListIndex(ele, params)
          );
        } else {
          index = state.myNewLearningList?.findIndex(
            (ele: any) => ele.catalog_data.guid == params.content_id
          );
        }
      } else {
        index = state.myNewLearningList?.findIndex((ele: any) =>
          findListIndex(ele, params)
        );
      }
      if (index != -1 && state.myNewLearningList != null) {
        const myLearningResults = state.myNewLearningList.slice();
        myLearningResults.splice(index, 1);
        state.myNewLearningList = myLearningResults;
      }
    },
    setEventResults(state: any, events: any) {
      const webinars = events;
      webinars.filter(
        (result: any) => new Date(result.event_date) > new Date()
      );
      webinars.sort(function (a: any, b: any) {
        const dateA = a.event_date;
        const dateB = b.event_date;
        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      });
      state.eventResults = webinars;
    },
    setPodcastResults(state: any, results: any) {
      state.podcastResults = results;
    },
    setAchievements(state: any, progress: any) {
      if (progress?.achievements) {
        state.achievements = progress.achievements;
      }
    },
    setUserEntitlements(state: any, data: any) {
      if (data?.entitlements) {
        state.entitlements = data.entitlements;
      }
    },
    setFilledLearningList(state: any, list: any) {
      let filledList = state.myNewLearningList ?? [];
      const formattedList = list?.length
        ? list.map((item: any) => {
            const newItem: any = {};
            if (item.type === "path") {
              newItem.path_data = item;
              newItem.path_id = item.id;
            } else if (item.type === "track") {
              newItem.track_data = item;
            } else {
              newItem.catalog_data = item;
            }
            newItem.guid = item.guid;
            newItem.type = item.type;

            return newItem;
          })
        : [];
      filledList = [...filledList, ...formattedList];
      state.filledLearningList = condenseDuplicates(filledList);
    },
  },
  actions: {
    async fetchStatus({ commit }: any) {
      await fetchUserStatus()
        .then((data) => {
          commit("setStatus", data);
        })
        .catch(() => {
          // do nothing
        });
    },

    fetchMyListData({ commit }: any) {
      let page = 1;

      function fetchList(fetchPage: number) {
        fetch(fetchPage)
          .then((data) => {
            if (data?.data) {
              commit("appendmyListResults", data.data);
              if (data.metadata.total_pages > fetchPage) {
                page += 1;
                fetchList(page);
              }
            } else {
              commit("appendmyListResults", []);
            }
            commit("setBoxShowable");
          })
          .catch(() => {
            // intentional
          });
      }

      fetchList(page);
    },

    fetchListByType({ commit }: any, type: string) {
      fetchByType(type)
        .then((data) => {
          if (data.data) {
            commit("addMyListResults", data.data);
          }
          if (data == null) {
            commit("setmyListResults", []);
          }
        })
        .catch(() => {
          // intentional
        });
    },

    fetchMiddlewareData({ commit }: any, type: string) {
      carousel(type)
        .then((data) => {
          if (type == "webinar") {
            commit("setEventResults", data);
          } else if (type == "podcast") {
            commit("setPodcastResults", data);
          }
        })
        .catch(() => {
          // intentional
        });
    },
    removeContentFromMyList({ commit }: any, { routeName, result }: any) {
      const params = {
        type: routeName != "forYou" ? result.type : null,
        content_id: parseInt(result.id),
        page: routeName,
      };
      remove(params)
        .then(() => {
          commit("removeFromMyListResults", params);
          commit("removeFromMyLearningResults", params);
        })
        .catch(() => {
          // do nothing
        });
    },
    addContentToMyList({ dispatch }: any, { routeName, result }: any) {
      const type = routeName != "forYou" ? result.type : null;
      add({
        type: type,
        content_id: result.id ? parseInt(result.id) : null,
        content_guid: result.guid != "" ? result.guid : null,
        deleted_at: null,
      })
        ?.then(() => {
          dispatch("fetchListByType", type);
        })
        .catch(() => {
          // do nothing
        });
    },
    async fetchProgress({ commit }: any) {
      await fetchUserProgress()
        .then((data) => {
          commit("setAchievements", data);
        })
        .catch(() => {
          // do nothing
        });
    },
  },
};
