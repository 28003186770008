const sanitizeUrl = await import("@braintree/sanitize-url");

export default function openWindow(
  url: string,
  target: string,
  windowFeatures: string
): void {
  const sanitizedUrl = sanitizeUrl.sanitizeUrl(url);
  window.open(sanitizedUrl, target, windowFeatures);
}
