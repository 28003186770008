import { z } from "zod";

import http from "@/services/http";

const userListParams = z.object({
  type: z.string().nullable(),
  content_id: z.number().nullish(),
  content_guid: z.string().nullish(),
  deleted_at: z.string().nullish(),
});

export type ContentParams = z.infer<typeof userListParams>;

export function add(content: ContentParams) {
  if (!content.content_id && !content.content_guid) return;

  return http().post(`${window.env.PROFILE_API_LOCATION}/myList`, {
    UserList: content,
  });
}

export async function fetch(page: number) {
  const baseUrl = `${window.env.PROFILE_API_LOCATION}/myList?per=50`;
  const url = page > 1 ? `${baseUrl}&page=${page}` : baseUrl;

  const { data } = await http().get(url);

  return data;
}

export async function fetchByType(type: string) {
  const baseUrl = `${window.env.PROFILE_API_LOCATION}/myList?per=50`;
  const url = type ? `${baseUrl}&type=${type}` : baseUrl;
  const { data } = await http().get(url);

  return data;
}

export async function remove(params: any) {
  if (isNaN(params.content_id)) return;
  await http().delete(
    `${window.env.PROFILE_API_LOCATION}/myList/${params.content_id}`,
    {
      data: { type: params.type },
    }
  );
}
