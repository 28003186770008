interface Publisher {
  "@type": string;
  name: string;
}

interface SchemaJsonld {
  "@context": string;
  "@type": string;
  name?: string;
  description?: string;
  timeRequired?: string;
  educationalLevel?: string;
  publisher?: Publisher | null;
  url?: string;
  hasPart?: SchemaJsonld[] | null;
}

export type { SchemaJsonld };

// what we'll default too if we can't determine the type
const baseType = "LearningResource";

// map of supported types and their corresponding schema.org types
const keyTypes = {
  course: "Course",
  podcast: "PodcastEpisode",
  video: "VideoObject",
  "video-track": "VideoObject",
  webinar: "Webinar",
  tutorial: baseType,
  path: baseType,
  "instructor-led": "Course",
  "practice-exam": "Exam",
  "pre-assessment": baseType,
  "post-assessment": baseType,
};

// set the @type property
function setType(type: string): string {
  if (!type || type == "") {
    return baseType;
  }

  const return_type = keyTypes[type as keyof typeof keyTypes];

  if (!return_type) {
    return baseType;
  }

  return return_type;
}

// set the name property
function setName(result: any): string {
  if (result?.name) {
    return result.name;
  }

  if (result?.title) {
    return result.title;
  }

  return "";
}

// set the description property
function setDescription(result: any): string {
  if (result?.summary) {
    return result.summary;
  }

  if (result?.description) {
    return result.description;
  }

  return "";
}

// set the duration property
function setDuration(result: any): string {
  // duration is in seconds, convert to minutes leaving any remainder as seconds then to ISO 8601 duration format
  if (result?.duration_sec) {
    const minutes = Math.floor(result.duration_sec / 60);
    const seconds = result.duration_sec % 60;
    return `PT${minutes}M${seconds}S`;
  }

  return "";
}

// set publisher property from result.publisher_name
function setPublisher(result: any): Publisher | null {
  if (result?.publisher_name) {
    return {
      "@type": "Organization",
      name: result.publisher_name,
    };
  }

  return null;
}

// set <?property?> property from result.<?property?>
function setProperty(result: any, property: string): string {
  if (result?.[property as keyof typeof result]) {
    return result[property as keyof typeof result];
  }

  return "";
}

// create the schema
export default function schemaJsonld(result: any): SchemaJsonld {
  return {
    "@context": "https://schema.org",
    "@type": setType(result.type),
    name: setName(result),
    description: setDescription(result),
    timeRequired: setDuration(result),
    educationalLevel: setProperty(result, "difficulty_type"),
    publisher: setPublisher(result),
    url: setProperty(result, "url"),
  };
}
