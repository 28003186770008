import unauthenticatedHttp from "@/services/unauthenticatedHttp";

export async function certification(id: string) {
  await unauthenticatedHttp().get(
    `${window.env.UNAUTH_API}/certifications/${id}`
  );
}

export async function certifications() {
  await unauthenticatedHttp().get(`${window.env.UNAUTH_API}/certifications`);
}

export async function fetchUnauthCertification(id: number) {
  const { data } = await unauthenticatedHttp().get(
    `${window.env.UNAUTH_API}/certifications/${id}`
  );
  return data;
}
