<script lang="ts">
import { defineComponent } from "vue";

import communityResult from "@/views/CommunityLanding/communityResult.vue";

export default defineComponent({
  name: "CertificationCommunities",
  components: {
    communityResult,
  },
  props: {
    certification: {
      type: Object,
      required: true,
    },
    communities: {
      type: Object,
      default: null,
    },
  },
});
</script>
<template>
  <div class="pb-4 lg:px-44">
    <div class="mt-5 text-40 text-black-light">
      <span id="connect" class="scroll-mt-28">Connect</span>
    </div>
    <p class="text-18">
      Join a community on the Cisco Learning Network to connect with
      certification experts and get learning guidance from your peers.
    </p>
    <div class="container mx-auto mt-10 md:px-4">
      <h2 class="mb-4 text-18 font-medium text-black-dark dark:text-white-dark">
        Popular
        <div class="text-18 font-bold md:inline-block">communities</div>
      </h2>
      <div class="flex-row gap-8 pt-4 lg:flex">
        <community-result
          v-if="communities"
          :key="communities.Name"
          :community="communities.Name"
          :link="communities.URL"
          :followers="communities.Followers"
          :posts="communities.Posts"
          :articles="communities.Articles"
          :certification="true"
        />
      </div>
    </div>
  </div>
</template>
