const contentTypes = {
  path: {
    contentType: "learningpaths",
    contentGuid: "pathGUId",
    contentProgressPercentage: "pathProgressPercentage",
  },
  tutorial: {
    contentType: "tutorials",
    contentGuid: "GUID",
    contentProgressPercentage: "CompletionPercentage",
  },
  course: {
    contentType: "courses",
    contentGuid: "courseGUID",
    contentProgressPercentage: "courseCompletionPercentage",
  },
  challenge: {
    contentType: "challenges",
    contentGuid: "courseGUID",
    contentProgressPercentage: "courseCompletionPercentage",
  },
};

export function progressCalc(
  result: any,
  achievements: any,
  resultType: string
): number {
  const resultGuid = result?.guid;
  if (!resultGuid) return 0;

  const { contentType, contentGuid, contentProgressPercentage } =
    contentTypes[resultType as keyof typeof contentTypes];
  if (
    !achievements ||
    !achievements[contentType as keyof typeof achievements]
  ) {
    return 0;
  }
  const achievementsContentType =
    achievements[contentType as keyof typeof achievements];
  const completions = achievementsContentType["completions"] ?? [];
  const inProgress = achievementsContentType["In-progress"] ?? [];

  if (
    completions?.length > 0 &&
    completions.find(
      (item: any) => item[contentGuid as keyof typeof item] == resultGuid
    )
  ) {
    return 100;
  }
  if (inProgress?.length > 0) {
    const content = inProgress.find(
      (item: any) => item[contentGuid as keyof typeof item] == resultGuid
    );

    return content
      ? (content[contentProgressPercentage as keyof typeof content] ?? 0)
      : 0;
  }
  return 0;
}
