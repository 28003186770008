import telemetry from "@/utils/telemetry";

export function sendButtonCtaTelemetry(
  path: string,
  title: string,
  unauth: boolean,
  scenario?: string,
  pageToGo?: string,
  input?: string
) {
  if (scenario || pageToGo || input)
    return telemetry.click_on_cta_ceCredit(
      path,
      title,
      unauth,
      "button",
      scenario,
      pageToGo,
      input
    );
  return telemetry.click_on_cta(path, title, unauth, "button");
}

export function sendLinkCtaTelemetry(
  path: string,
  title: string,
  unauth: boolean,
  scenario?: string,
  pageToGo?: string
) {
  if (scenario || pageToGo)
    return telemetry.click_on_cta_ceCredit(
      path,
      title,
      unauth,
      "link",
      scenario,
      pageToGo
    );
  return telemetry.click_on_cta(path, title, unauth, "link");
}
